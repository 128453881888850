import React from 'react';
import { BrowserRouter as Router, Routes, Route, NavLink } from 'react-router-dom';
import HomePage from './pages/HomePage';
import ResultsPage from './pages/ResultsPage';
import logo from './assets/logo.png';

function App() {
  return (
    <Router>
      <div className="app">
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/results" element={<ResultsPage />} />
        </Routes>
        
        <nav className="navbar fixed-bottom navbar-expand-lg navbar-light" style={{backgroundColor: '#0FF'}}>
          {/* Changed anchor to NavLink to fix the accessibility warning */}
          <NavLink to="/" className="navbar-brand">
            <img src={logo} width="100px" alt="Logo" />
          </NavLink>
          <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNavDropdown">
            <ul className="navbar-nav">
              <li className="nav-item">
                <NavLink 
                  to="/" 
                  className={({ isActive }) => isActive ? "nav-link active" : "nav-link"}
                >
                  Home{({ isActive }) => isActive && <span className="sr-only">(current)</span>}
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink 
                  to="/results" 
                  className={({ isActive }) => isActive ? "nav-link active" : "nav-link"}
                >
                  2DIGIT
                </NavLink>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </Router>
  );
}

export default App;
